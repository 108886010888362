<template>
  <span class="icon" :class="size">
    <object v-if="mySvg" v-bind:data="mySvg" type="image/svg+xml"></object>
  </span>
</template>

<script>
export default {
  props: {
    settingId: {
      type: Number,
      default: 0,
    },
    size: {
      type: String,
      default: 'is-medium',
    },
    color: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      mySvg: this.settingId
        ? require(`@/assets/images/settings/setting_${this.settingId}.svg`)
        : '',
    }
  },
}
</script>

<style lang="scss" scoped>
object {
  width: 100%;
}
</style>
